import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import { Link } from "gatsby"

const CheckoutSuccess = () => (
  <Layout>
    <Header/>

    <p className="section-body text-gray-400">
      You successfully subscribed for a year of Linksaber. <br/>
      The subscription is not recurring, meaning you won't be charged unless you want to do so. <br/><br/>

      I truly hope Linksaber will do you well, and in any case, feel free to contact me on Twitter, I'm <Link className="underline" to="https://twitter.com/albigiu" target="_blank">@albigiu</Link> on there.<br/>
      Also, you're amazing, and you've just made my day.
    </p>
  </Layout>
)


export default CheckoutSuccess